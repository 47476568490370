import React, { useState, useEffect, useRef, useMemo,useContext } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import './ContactUsForm.scss';

import form_icon from '../../assets/form_icon.svg';
import {resources_callapi} from'../../common/CallApi';
import { DataContext } from '../../App';

import close_btn from '../../assets/close_btn.png';

// components
import Loading from '../Loading/Loading';

function ContactUsForm() {
  const { t } = useTranslation();
  const allpagedata = useContext(DataContext);
  const [ContactusContent, setContactusContent] = React.useState();
  const [MonClass, setMonClass] = React.useState("placeholder");
  const [YearClass, setYearClass] = React.useState("placeholder");
  const [AdultiNumInput, setAdultNumInput] = React.useState(true);
  const [des_array, setDesArray] = React.useState([]);
  const [formConfirm, setFormConfirm] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const { register,formState: { errors }, handleSubmit, setValue, getValues, reset  } = useForm({
    defaultValues: {
      fullname: '',
      telphone: '',
      email: '',
      no_of_night: '',
      travel_month: '',
      travel_year: '',
      adult_num:0,
      children_num:0
    }
  });
  const curLang = i18n.resolvedLanguage;
  useEffect(()=>{
    let des_afiric_place = [];
    let des_rotw_place = [];


    if (allpagedata && allpagedata.destination_afric_pagelist) {
      des_afiric_place = allpagedata.destination_afric_pagelist.filter(item=>{const [name,subname] = item.slug.split('_'); return subname === undefined});
    }
    if (allpagedata && allpagedata.destination_rotw_pagelist) {
      des_rotw_place = allpagedata.destination_rotw_pagelist.filter(item=>{const [name,subname] = item.slug.split('_'); return subname === undefined});
    }
    
    let destination_array = [
      {
        "categories":"africa",
        "place":des_afiric_place
      },
      {
        "categories":"rest_of_world",
        "place":des_rotw_place
      }
    ];

    setDesArray(destination_array);

  },[allpagedata])

const setNativeValue = (element, value) => {
  let lastValue = element.value;
  element.value = value;
  let event = new Event("input", { target: element, bubbles: true });
  // React 15
  event.simulated = true;
  // React 16
  let tracker = element._valueTracker;
  if (tracker) {
      tracker.setValue(lastValue);
  }
  element.dispatchEvent(event);
}

  const reloadPage = () => {
    reset();
    window.location.reload(); 
  }

  const onSubmit = (data) => {
    
    // fetch(YOUR_URL, { method: 'POST', body: {'name':'test'} })
    //   .then(response => response.json())
    //   .then(resultData => console.log(resultData));
    console.log('ContactUsForm onsubmit');
    console.log("🚀 ~ ContactUsForm ~ data:", data);
    setFormLoading(true);

    var input_name = document.getElementById("wpforms-1644-field_1");
    setNativeValue(input_name, data.fullname);
    var input_tel = document.getElementById("wpforms-1644-field_7");
    setNativeValue(input_tel, data.telphone);
    var input_email = document.getElementById("wpforms-1644-field_8");
    setNativeValue(input_email, data.email);
    var input_night = document.getElementById("wpforms-1644-field_9");
    setNativeValue(input_night, data.no_of_night);
    var input_travel_month = document.getElementById("wpforms-1644-field_23");
    setNativeValue(input_travel_month, data.travel_month);
    var input_travel_year = document.getElementById("wpforms-1644-field_24");
    setNativeValue(input_travel_year, data.travel_year);
    var input_adult_num = document.getElementById("wpforms-1644-field_25");
    setNativeValue(input_adult_num, data.adult_num);
    var input_children_num = document.getElementById("wpforms-1644-field_26");
    setNativeValue(input_children_num, data.children_num);
    var input_enquiry = document.getElementById("wpforms-1644-field_27");
    setNativeValue(input_enquiry, data.enquiry);
    var input_des_selection = document.getElementById("wpforms-1644-field_29");
    setNativeValue(input_des_selection, data.des_selection.join(' , '));

    var wpform_submit = document.getElementById("wpforms-submit-1644");
    wpform_submit.click();

    setTimeout(()=>{
      setFormLoading(false);
      var wpform_done = document.getElementById("wpforms-confirmation-1644");
      if (wpform_done) {
        console.log('ContactUsForm onsubmit done');
        setFormConfirm(true);
      }

    },5000)
  };
  
  const UpdateSlectedDesList = (placeItem) => {
    if(getValues("des_selection").find((pitem)=>pitem === placeItem)) {
      const newList = getValues("des_selection").filter((pitem)=>pitem !== placeItem);
      setValue("des_selection",newList);
    } else {
      getValues("des_selection").push(placeItem);
    }
    // console.log("🚀 ~ UpdateSlectedDesList ~ placeItem:", placeItem, getValues("des_selection"))

  }

  useEffect(() => {
    const get_contactus_data = async () => {
      const api_contactus_data = await resources_callapi("contactus_form");
      setContactusContent(api_contactus_data.find((item) => item.slug == "form"));
    }
    get_contactus_data();
    console.log("🚀 ~ ContactusContent:", ContactusContent)

  }, [curLang]);

  // useEffect(() => {
  //   reset({
  //     data: "test",
  //   })
  // }, [isSubmitSuccessful])


    return (
      (ContactusContent && Object.keys(ContactusContent).length > 0)?
        <div className="contactus-form-section">
          <div className={`confirm-msg ${(formConfirm)?'active':''}`}>
            <img src={close_btn} alt="" className="close-btn" onClick={reloadPage} />
            {/* <img className='msg-img' src="https://api.wildsensesholidays.com/wp-content/uploads/2024/06/contact-us-scaled.jpeg" alt="" /> */}
            <img className='msg-img' src={(curLang === 'tc')? "https://api.wildsensesholidays.com/wp-content/uploads/2024/06/contact-us-TC.jpg":"https://api.wildsensesholidays.com/wp-content/uploads/2024/06/contact-us-scaled.jpeg"} alt="" />
            {/* <div className="form-title">Thanks for contacting us! We will be in touch with you shortly.</div> */}
          </div>
          <div className="contactus-form-container" id='contactus-form'>
            <div className="contactus-form-container" >
              <img src={form_icon} alt="" className="form-icon" />
              <div className="form-title">{ContactusContent.acf.form_title[curLang]}</div>
              {/* {(ContactusContent  && Object.keys(ContactusContent).length > 0)? <div className="contactus-form wp-form" dangerouslySetInnerHTML={{__html: ContactusContent.content.rendered}}></div> :<div></div>} */}
              <form className="contactus-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="item-left-section">

                  <label className="input-item fullname">
                    <div className="item-name">{ContactusContent.acf.field_name[curLang]}</div>
                    <input className="item-input" type="text" autoComplete="off" placeholder={ContactusContent.acf.field_name_placeholder[curLang]} {...register("fullname", {
                      required: { value: true, message: {"tc":"請輸入你的姓名.", "en":"Please insert the name." }}
                      // maxLength: { value: 3, message: {"tc":"不得超過三個字", "en":"不得超過三個字 en" } }
                    })} />
                    {errors.fullname && <div className="error-msg">{errors.fullname.message[curLang]}</div>} 
                  </label>
                  <div className="contact-items-section">
                    <label className="input-item telphone">
                    <div className="item-name">{ContactusContent.acf.field_tel_no[curLang]}</div>
                    <input className="item-input" type="tel" autoComplete="off" placeholder={ContactusContent.acf.field_tel_no_placeholder[curLang]} {...register("telphone", {
                      required: { value: true, message: {"tc":"請輸入你的電話號碼.", "en":"Please enter the number." }},
                      pattern: { value: /^[0-9]*$/, message: {"tc":"請輸入你的電話號碼.", "en":"Phone number must contain numbers only." } }
                    })} />
                    {errors.telphone && <div className="error-msg">{errors.telphone.message[curLang]}</div>} 
                    </label>
                    <label className="input-item email">
                    <div className="item-name">{ContactusContent.acf.field_email[curLang]}</div>
                    <input className="item-input" type="email" autoComplete="off" placeholder={ContactusContent.acf.field_email_placeholder[curLang]} {...register("email", {
                      required: { value: true, message: {"tc":"請輸入你的電郵.", "en":"Please enter your email address." }}
                    })} />
                    {errors.email && <div className="error-msg">{errors.email.message[curLang]}</div>} 
                    </label>
                  </div>
                  <label className="input-item num_of_night">
                    <div className="item-name">{ContactusContent.acf.field_num_of_nights[curLang]}</div>
                    {/* need to see how to handle the error display update */}
                    <input className="item-input" type="tel" autoComplete="off" placeholder={ContactusContent.acf.field_num_of_nights_placeholder[curLang]} {...register("no_of_night", {
                      pattern: { value: /^[0-9]*$/, message: {"tc":"請輸入行程晚數.", "en":"Please enter a number of nights." } }
                    })} />
                    {errors.no_of_night && <div className="error-msg">{errors.no_of_night.message[curLang]}</div>} 
                  </label>
                  <div className="trvel-period-section">
                    <div className="section-title">{ContactusContent.acf.form_travel_period_title[curLang]}</div>
                    <label className="input-item travel_month">
                      <div className="item-name">{ContactusContent.acf.field_travel_period_month[curLang]}</div>
                      <select className={MonClass} name="travel_month" 
                        {...register("travel_month",{
                          onChange: (e)=> { if (e.target.value !== "") {setMonClass("")} else {setMonClass("placeholder")}}
                        })}>
                        <option value="">{ContactusContent.acf.field_travel_period_month_placeholder[curLang]}</option>
                        {[...Array(12)].map((i, item) =>{
                          let month = new Date(0, item).toLocaleString('en', { month: 'short' });
                          return <option key={item} value={month}>{month}</option>
                        })}
                      </select>
                    </label>
                    <label className="input-item travel_year">
                      <div className="item-name">{ContactusContent.acf.field_travel_period_year[curLang]}</div>
                      <select className={YearClass} name="travel_year" 
                        {...register("travel_year",{
                          onChange: (e)=> { if (e.target.value !== "") {setYearClass("")} else {setYearClass("placeholder")}}
                        })}>
                        <option value="">{ContactusContent.acf.field_travel_period_year_placeholder[curLang]}</option>
                        {[...Array(10)].map((i,item) =>{
                          const year = new Date().getFullYear();
                          return <option key={item} value={year + item}>{year + item}</option>
                        })}
                      </select>
                    </label>
                  </div>

                </div>
                
                <div className="item-right-section"> 
                  <div className="num-people-section">
                    <div className="section-title">{ContactusContent.acf.form_num_of_people_title[curLang]}</div>
                    <label className="input-item adult-num">
                      <div className="item-name">{ContactusContent.acf.field_num_of_adults[curLang]}</div>
                      <div className="item-input-section">
                        <button className="num-control-icon minus-icon" onClick={(e)=>{ e.preventDefault();if (getValues("adult_num") > 0) {setValue("adult_num",(getValues("adult_num")-1));}}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z" fill="#D9B783"/>
                          </svg>
                        </button>
                        <input className="num-input" type="text" autoComplete="true" disabled={AdultiNumInput} size="2" defaultValue="0" {...register("adult_num", {
                          value:0,
                          required: { value: true, message: {"tc":"請輸入同行人數.", "en":"Please enter number of adults."}},
                          min: { value: 1, message: {"tc":"請輸入同行人數.", "en":"Please enter number of adults." } }
                        })} />
                        <button className="num-control-icon add-icon" onClick={(e)=>{e.preventDefault(); if (getValues("adult_num") < 20) {setValue("adult_num",(getValues("adult_num")+1))}}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V12.75H8C7.58579 12.75 7.25 12.4142 7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H11.25V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#D9B783"/>
                          </svg>
                        </button>
                      </div>
                      <div className="item-remark">{ContactusContent.acf.field_num_of_adults_remark[curLang]}</div>
                      {errors.adult_num && <div className="error-msg">{errors.adult_num.message[curLang]}</div>} 
                    </label>
                    <label className="input-item children-num">
                      <div className="item-name">{ContactusContent.acf.field_num_of_child[curLang]}</div>
                      <div className="item-input-section">
                        <button className="num-control-icon minus-icon" onClick={(e)=>{e.preventDefault(); if (getValues("children_num") > 0) {setValue("children_num",(getValues("children_num")-1));}}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z" fill="#D9B783"/>
                          </svg>
                        </button>
                        <input className="num-input" type="text" autoComplete="true" disabled={AdultiNumInput} size="2" {...register("children_num",{value: 0})} />
                        <button className="num-control-icon add-icon" onClick={(e)=>{e.preventDefault(); if (getValues("children_num") < 20) {setValue("children_num",(getValues("children_num")+1))}}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V12.75H8C7.58579 12.75 7.25 12.4142 7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H11.25V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#D9B783"/>
                          </svg>
                        </button>
                      </div>
                      <div className="item-remark">{ContactusContent.acf.field_num_of_child_remark[curLang]}</div>
                    </label>
                  </div>
                  <label className="input-item enquiry">
                    <div className="item-name">{ContactusContent.acf.field_enquiry[curLang]}</div>
                    {/* need to see how to handle the error display update */}
                    {/* {errors.test && <div className="error-msg">{errors.test.message[curLang]}</div>}  */}
                    <textarea  className="enquiry-input" type="text" autoComplete="off" placeholder={ContactusContent.acf.field_enquiry_placeholder[curLang]} {...register("enquiry", {
                      value:''
                      // maxLength: { value: 3, message: {"tc":"不得超過三個字", "en":"不得超過三個字 en" } }
                    })} />
                  </label>
                </div>

                <div className="item-des-section">
                  <div className="section-title">{ContactusContent.acf.form_destination_title[curLang]}</div>
                  {errors.des_selection && <div className="error-msg">{(curLang === 'tc')?'請選擇前往目的地.':'Please select the destination.'}</div>} 
                  <input style={{display:"none"}} className="num-input" type="text" size="2" defaultValue="0" {...register("des_selection", {
                    value:[],
                    validate: value => value.length > 0 || "",
                    // minLength: { value: 2, message: {"tc":"請選擇前往目的地.", "en":"Please select the destination."}},
                  })} />
                  
                  {des_array.map((item) => {
                    return <div className="categories-list">
                      <div className="item-categories">{t(`destination_${item.categories}`)}</div>
                      <div className="item-des-list">
                        {item.place.map((placeItem) => {
                          return <div className={`des-item ${(getValues("des_selection").length > 0 && getValues("des_selection").find((pitem)=>pitem === placeItem.acf.page_url_name.en)?'active':'')}`} onClick={(e)=>{e.preventDefault(); e.target.classList.toggle('active'); UpdateSlectedDesList(placeItem.acf.page_url_name.en);}}>{placeItem.acf.page_url_name[curLang]}</div>
                          
                        })}
                      </div>
                    </div>
                  })}

                </div>

                <label className="action-btn">
                  {(formLoading)? <Loading></Loading> :''}
                  <input className="sumbit-btn" disabled={formLoading} type="Submit" value={ContactusContent.acf.form_submit_btn[curLang]}/>
                  <input className="reset-btn" disabled={formLoading} type="Reset" value={ContactusContent.acf.form_reset_btn[curLang]} onClick={(e)=>{e.preventDefault(); reset();}} />
                </label>
              </form>

            </div>
          </div>
        </div>
       :<div></div>
    );
  }
  
  export default ContactUsForm;