import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios from 'axios';

// import en from "./i18n/en.json";
// import tc from "./i18n/tc.json";


const resources_callapi = async () => {
  let resp;
  await axios.get("https://api.wildsensesholidays.com/wp-json/wp/v2/main?_fields=id,slug,title.rendered,acf").then(
    result => {
      // console.log("test", result.data);
      resp = result.data;
    }
  )
  return resp;
}



const resources_api = await resources_callapi();
const resources_api_en = resources_api.find((item) => item.slug =='en').acf;
const resources_api_tc= resources_api.find((item) => item.slug =='tc').acf;

const resources = {
  en: {
    translation: {...resources_api_en},
  },
  tc: {
    translation: {...resources_api_tc},
  },
};
console.log("test 🚀 ~ page lang main:", resources)


i18n.use(initReactI18next).init({
  resources, // 會是所有翻譯資源
  fallbackLng: "en", // 如果當前切換的語言沒有對應的翻譯則使用這個語言
  lng: "en", // 預設語言
  lookupFromPathIndex:0,
  interpolation: {
    // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
    escapeValue: false,
  },
});

export default i18n;