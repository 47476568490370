
import React, { useState, useEffect } from 'react';
// import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import './Loading.scss';
// import i18n from 'i18next';
// import { useTranslation } from "react-i18next";




function Loading() {
    return (
      <div className="loading-icon-container">
        <div class="circle">
          <svg class="circle__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle class="circle__svg-circle" cx="50" cy="50" r="45"/>
          </svg>
        </div>
      </div>
    );
  }
  
  export default Loading;