// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';
import {Helmet} from "react-helmet";
import './WhoWeAre.scss';
import i18n from 'i18next';

import {resources_callapi} from'../../common/CallApi';

// image
// import banner from '../../assets/aboutus/banner.png';

// components

import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';


function WhoWeAre() {
    // const { t } = useTranslation();
    let location = useLocation();
    // const navigate = useNavigate();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;
    const [pageContent, setPageContent] = React.useState({});
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });


    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }

          
      const get_page_content = async () => {
        // const api_page_slug = categories+'_'+pagename;
        const api_page_slug = "whoweare";
        const api_page_content = await resources_callapi("about_us", api_page_slug);
        let render_content = [];
        if (api_page_content.length > 0 && api_page_content.find((item) => item.slug == api_page_slug)) {
          render_content = api_page_content.find((item) => item.slug == api_page_slug).acf;
          // const recommend_item_content = await resources_callapi("travelbymontab","",`per_page=3&include=${render_content.recommend_item.join()}`)
        }
        Promise.all([render_content]).then((content) => {
          console.log(content);
          setPageContent(content[0]);
        });
      }
      get_page_content();

    }, [curPath]);


    return (
      (pageContent && Object.keys(pageContent).length > 0) ? (
        <div className="page-container">
          <div className={`aboutus-whoweare-page-container`}>
            <Helmet>
                <title>About us</title>
            </Helmet>

            <div className="banner-section" style={(isMobile)?{backgroundImage: `url(`+ pageContent.banner_img.mobile +`)`}:{backgroundImage: `url(`+ pageContent.banner_img.desktop +`)`}} >
              <div className="banner-text-section">
                <div className="page-title">
                  <div className="page-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                    </svg>
                  </div>
                  <div className="page-name">{(pageContent.banner_page_title)?pageContent.banner_page_title[curLang]:''}</div>
                </div>
                <div className="sub-title">{(pageContent.banner_page_subtitle)?pageContent.banner_page_subtitle[curLang]:''}</div>
                <div className="desp">{(pageContent.banner_page_desp)?pageContent.banner_page_desp[curLang]:''}</div>
              </div>
            </div>
            <ContactUs></ContactUs>

            <div className="info-destinations-section box-box-section">
              <div className="left-box img-box">
                <img src={(pageContent.our_destinations)?pageContent.our_destinations.our_destinations_image:''} alt="" />
              </div>
              <div className="right-box text-box">
                <div className="box-title">{(pageContent.our_destinations)?pageContent.our_destinations.our_destinations_title[curLang]:''}</div>
                <div className="box-line"><div className="line"></div></div>
                <div className="box-quote">{(pageContent.our_destinations.our_destinations_qoute)?pageContent.our_destinations.our_destinations_qoute[curLang]:''}</div>
                {(pageContent.our_destinations.our_destinations_details !== "")?<div className="box-desp" dangerouslySetInnerHTML={{__html: pageContent.our_destinations.our_destinations_details[curLang]}} ></div>:"" }
              </div>
            </div>
            <div className="info-travel-section box-box-section">
              <div className="left-box text-box">
                <div className="box-title">{(pageContent.our_travel_specialists.our_travel_specialists_title)?pageContent.our_travel_specialists.our_travel_specialists_title[curLang]:''}</div>
                <div className="box-line"><div className="line"></div></div>
                <div className="box-quote">{(pageContent.our_travel_specialists.our_travel_specialists_qoute)?pageContent.our_travel_specialists.our_travel_specialists_qoute[curLang]:''}</div>
                {(pageContent.our_travel_specialists.our_travel_specialists_details !== "")?<div className="box-desp" dangerouslySetInnerHTML={{__html: pageContent.our_travel_specialists.our_travel_specialists_details[curLang]}} ></div>:"" }
              </div>
              <div className="right-box img-box">
                <img src={(pageContent.our_travel_specialists)?pageContent.our_travel_specialists.our_travel_specialists_image:''} alt="" />
              </div>
            </div>

          </div>
          <ContactUsForm></ContactUsForm>
        </div>
      ) : <div></div>
    );
  }
  
  export default WhoWeAre;