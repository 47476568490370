// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import './TermsAndCondition.scss';
import i18n from 'i18next';
import Slider from "react-slick";

import {resources_callapi} from'../../common/CallApi';
import { DataContext } from '../../App';

// image
// import itemIng from '../../assets/news/img1.png';

// components
import TravelIdeaTabItem from '../../components/TravelIdeaTabItem/TravelIdeaTabItem';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';


function TermsAndCondition() {
    const { t } = useTranslation();
    const {categories,pagename} = useParams();
    const [pageContent, setPageContent] = React.useState({});
    const [pageListItems, setPageList] = React.useState({});
    const [pageTravelBlogItemList, setPageTravelBlogItemList] = React.useState([]);
    const [pageRecommendItemList, setpageRecommendItemList] = React.useState([]);
    const [pageListActive, setPageListActive] = React.useState(false);
    const [subPageListActive, setSubPageListActive] = React.useState({"experience":false,"destination":false});
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    const allpagedata = useContext(DataContext);
    
    let location = useLocation();
    const navigate = useNavigate();
    const curLang = i18n.resolvedLanguage;
    const curPath = location.pathname;


    useEffect(() => {
      if (curPath.includes("/tc")) {
        i18n.changeLanguage("tc");
      } else {
        i18n.changeLanguage("en");
      }

      const get_page_content = async () => {
        // const api_page_slug = categories+'_'+pagename;
        const api_page_slug = "tnc";
        const api_page_content = await resources_callapi("travel_info", api_page_slug);
        if (api_page_content.length > 0 && api_page_content.find((item) => item.slug == api_page_slug)) {
          let render_content = api_page_content.find((item) => item.slug == api_page_slug).acf;
          const api_blog_data = await resources_callapi("travel_info_blog","",`per_page=100`)
          const recommend_item_content = await resources_callapi("travelbymontab","",`per_page=3&include=${render_content.recommend_item.join()}`)
          Promise.all([render_content, api_blog_data,recommend_item_content]).then((content) => {
            console.log(content);
            setPageContent(content[0]);
            setPageTravelBlogItemList(content[1]);
            setpageRecommendItemList(content[2]);
          });
        }
      }
      get_page_content();

      const get_all_sub_pagelist = () => {

        if (allpagedata && allpagedata.travel_info_pagelist) {
          let pageList = [];
          allpagedata.travel_info_pagelist.map((pageListItem)=>{

            const [name,subname] = pageListItem.slug.split('_');
            pageList.push({'categorie':name,'url_name':name,'url_subname':subname,'name':pageListItem.acf.page_url_name});

          })
          setPageList(pageList);
        }

      }
      get_all_sub_pagelist();

    }, [curPath,allpagedata]);


    return (

      (Object.keys(pageListItems).length > 0 && pageContent && Object.keys(pageContent).length > 0) ? (
        <div className="page-container">
          <Helmet>
              <title>Terms and Condition</title>
          </Helmet>
          <div className={`tnc-page-container ${pagename}`}>

            <div className="banner-section" style={(isMobile)?{backgroundImage: `url(`+ pageContent.banner_img.mobile +`)`}:{backgroundImage: `url(`+ pageContent.banner_img.desktop +`)`}}>
              <div className="banner-text-section">
                <div className="page-title">
                  <div className="page-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                    </svg>
                  </div>
                  <div className="page-name">{(pageContent.banner_page_title)?pageContent.banner_page_title[curLang]:''}</div>
                </div>
                <div className="sub-title">{(pageContent.banner_page_subtitle)?pageContent.banner_page_subtitle[curLang]:''}</div>
              </div>
            </div>
            
            <ContactUs></ContactUs>
            <div className="content-section">
              <div className={`page-list-section ${(pageListActive)?'active':''}`}>
                <ul className="page-list">
                  <div className="close-section show-mb" onClick={()=>setPageListActive(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z" fill="black"/>
                    </svg>
                  </div>
                  {(pageListItems) ? 
                    pageListItems.map( (pageItem,pageIdx) => {
                      return <li key={pageIdx} className={`page-list-item ${(curPath.includes(pageItem.categorie))?'active':''}`} onClick={()=>{setSubPageListActive({...subPageListActive,[pageItem.categorie]:true});}}>
                        <Link to={`/${curLang}/travel-info/${pageItem.url_name}/${(pageItem.url_subname)?pageItem.url_subname:''}`} className="page-link">
                          <div className="title">
                            <div className="name">{pageItem.name[curLang]}</div>
                            <div className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M8.46967 4.21967C8.17678 4.51256 8.17678 4.98744 8.46967 5.28033L15.1893 12L8.46967 18.7197C8.17678 19.0126 8.17678 19.4874 8.46967 19.7803C8.76256 20.0732 9.23744 20.0732 9.53033 19.7803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L9.53033 4.21967C9.23744 3.92678 8.76256 3.92678 8.46967 4.21967Z" fill="black"/>
                              </svg>
                            </div>
                          </div>
                        </Link>

                      </li>
                    }

                    )
                  :''}
                </ul>
              </div>

              <div className={`content-details-section`}>
                <div className="details-sub-section">
                  <div className="title">{(pageContent.content_page_title)?pageContent.content_page_title[curLang]:''}</div>
                  <div className="line-section"><div className="line"></div></div>

                  {(pageContent.tnc) ?
                    <div className="content-desp" dangerouslySetInnerHTML={{__html: pageContent.tnc[curLang]}}></div>
                  :""}

                </div>
              </div>

            </div>
            
            { (pageRecommendItemList && pageRecommendItemList.length > 0) ?
              <div className="recommend-section">
                <div className="section-title">{t('recommended_journeys')}</div>
                <div className="recommend-item-list">
                      {pageRecommendItemList.map((recommendItem,idx)=>{
                        return <TravelIdeaTabItem key={idx} itemdetails={recommendItem.acf}></TravelIdeaTabItem>
                      })}
                </div>
              </div>
            :''}
            
          </div>

          <ContactUsForm></ContactUsForm>
        </div>
      ) : <div></div>
    );
  }
  
  export default TermsAndCondition;