import './App.scss';
import React, { useState, useEffect  } from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';

// import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import { Navigate,useNavigate, useLocation   } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { BrowserRouter as Router, Routes , Route, Outlet } from 'react-router-dom';
import { BrowserRouter as Router, Routes , Route, Outlet  } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


// common js

import {resources_callapi} from'./common/CallApi';
import ScrollToTop from './components/common/ScrollToTop';
import CheckLang from './components/common/CheckLang';

// components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import BackToTop from './components/BackToTop/BackToTop';

// page
import HomePage from './page/HomePage/HomePage';
import MigrationMap from './page/MigrationMap/MigrationMap';
import WhoWeAre from './page/WhoWeAre/WhoWeAre';
import ExtraordinaryMoments from './page/ExtraordinaryMoments/ExtraordinaryMoments';
import PositiveImpact from './page/PositiveImpact/PositiveImpact';
import AboutSafari from './page/AboutSafari/AboutSafari';
import Destination from './page/Destination/Destination';
import Itineraries from './page/Itineraries/Itineraries';
import ItinerariesDetails from './page/ItinerariesDetails/ItinerariesDetails';
import LatestNews from './page/LatestNews/LatestNews';
import TravelBlog from './page/TravelBlog/TravelBlog';
import Faqs from './page/Faqs/Faqs';
import TermsAndCondition from './page/TermsAndCondition/TermsAndCondition';
import TravelInfoDetails from './page/TravelInfoDetails/TravelInfoDetails';
import ContactUs from './page/ContactUs/ContactUs';

export const DataContext = React.createContext({})




function App() {
  const { i18n } = useTranslation();
  const queryClient = new QueryClient();

  const [apiResponse, setApiResponse] = useState({});

  useEffect(() => {
    const get_pagelist = async () => {
      const api_pageList_africa = await resources_callapi("destination_africa", "","_fields=id,slug,acf.page_url_name,acf.homepage_icon_img&per_page=100","other");
      const api_pageList_rotw = await resources_callapi("destination_rotw", "","_fields=id,slug,acf.page_url_name,acf.homepage_icon_img&per_page=100","page_list");
      const api_pageList_itineraries = await resources_callapi("itineraries", "","per_page=100","page_list");
      const api_pageList_travel_info = await resources_callapi("travel_info", "","per_page=100","page_list");
      const api_pageList_aboutus = await resources_callapi("about_us", "","per_page=100","page_list");
      const api_pageList_aboutsafari = await resources_callapi("aboutsafari", "","","page_list");
      const api_contactus_data = await resources_callapi("contactus_form");

      Promise.all([
        api_pageList_africa,
        api_pageList_rotw,
        api_pageList_itineraries,
        api_pageList_travel_info,
        api_pageList_aboutus,
        api_pageList_aboutsafari,
        api_contactus_data
      ]).then((pageList) => {

        let itinerariesPageList = {"experience":[],"destination":[]};
        pageList[2].map((pageListItem)=>{
          const [name,subname] = pageListItem.slug.split('_');
          if(itinerariesPageList[name]) {
            itinerariesPageList[name].push({'categorie':name,'url':name+'/'+subname,'name':pageListItem.acf.page_url_name});
          }
        });


        setApiResponse({
          "destination_afric_pagelist": pageList[0],
          "destination_rotw_pagelist": pageList[1],
          "itineraries_pagelist": itinerariesPageList,
          "travel_info_pagelist": pageList[3],
          "aboutus_pagelist": pageList[4],
          "aboutsafari_pagelist": pageList[5],
          "contactus_form": pageList[6].find((item) => item.slug == "form")
        });
        
      });
    };
    get_pagelist();
  },[]);
  
  
  return (
    <QueryClientProvider client={queryClient}>
      <DataContext.Provider value={apiResponse}>
        <div className="App">
          <header className="App-header">
            <Router>
              <ScrollToTop />
              {/* <CheckLang/> */}
              <Header />
              <div className="page-container">
                {/* update to gen by function */}
                <Routes>
                  <Route path="/" element={<Navigate to={i18n.language} />}/>
                  <Route path="/:lang" element={<HomePage />} />
                  <Route path="/:lang/aboutus-whoweare" element={<WhoWeAre />} />
                  <Route path="/:lang/aboutus/whoweare" element={<WhoWeAre />} />
                  <Route path="/:lang/aboutus/extraordinary-moments" element={<ExtraordinaryMoments />} />
                  <Route path="/:lang/aboutus/positive-impact" element={<PositiveImpact />} />
                  <Route path="/:lang/aboutsafari" element={<AboutSafari />} />
                  <Route path="/:lang/aboutsafari/:pagename" element={<AboutSafari />} />
                  <Route path="/:lang/aboutsafari/migrationmap" element={<MigrationMap />} />
                  <Route path="/:lang/destination/:categories/:pagename" element={<Destination />} />
                  <Route path="/:lang/destination/:categories/:pagename/:subpagename" element={<Destination />} />
                  <Route path="/:lang/destination/:categories/:pagename" element={<Destination />} />
                  <Route path="/:lang/itineraries/:categories/:pagename/" element={<Itineraries />} />
                  <Route path="/:lang/itineraries/:categories/:pagename/:subpagename" element={<ItinerariesDetails />} />
                  <Route path="/:lang/travel-info/latest-news" element={<LatestNews />} />
                  <Route path="/:lang/travel-info/travel-blog" element={<TravelBlog />} />
                  <Route path="/:lang/travel-info/faqs/:pagename?" element={<Faqs />} />
                  <Route path="/:lang/travel-info/tnc" element={<TermsAndCondition />} />
                  <Route path="/:lang/travel-info/info-details/:pagename" element={<TravelInfoDetails />} />
                  <Route path="/:lang/contact-us" element={<ContactUs />} />
                </Routes>
                <BackToTop></BackToTop>
                {(apiResponse && Object.keys(apiResponse).length > 0)? 
                  (apiResponse.contactus_form && Object.keys(apiResponse.contactus_form).length > 0) ?
                  <div className="wp-form-section">
                    <Helmet>
                        <script src="https://api.wildsensesholidays.com/wp-content/plugins/wpforms/assets/lib/jquery.validate.min.js?ver=1.20.0" id="wpforms-validation-js"></script>
                        <script src="https://api.wildsensesholidays.com/wp-content/plugins/wpforms/assets/js/share/utils.min.js?ver=1.8.8.3" id="wpforms-generic-utils-js"></script>
                        <script src="https://api.wildsensesholidays.com/wp-content/plugins/wpforms/assets/js/frontend/wpforms.min.js?ver=1.8.8.3" id="wpforms-js"></script>
                        <script src="https://api.wildsensesholidays.com/wp-content/plugins/wpforms/assets/js/frontend/wpforms-modern.min.js?ver=1.8.8.3" id="wpforms-modern-js"></script>
                    </Helmet>
                    <div className="contactus-form wp-form" dangerouslySetInnerHTML={{__html: apiResponse.contactus_form.content.rendered}}></div>
                  </div>
                  :<div></div>
                :<div></div>}
              </div>
              <Footer></Footer>
            </Router>
          </header>
        </div>
      </DataContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
