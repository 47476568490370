// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState,useEffect,useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
import { HashRouter as Router, Routes , Route, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import i18n from 'i18next';
import Slider from "react-slick";
import './HomePage.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {resources_callapi} from'../../common/CallApi';

// image
import top_info_icon from '../../assets/index/top_info_icon.png';

// components
// import CheckLang from '../../components/common/CheckLang';
import TravelIdeaTab from '../../components/TravelIdeaTab/TravelIdeaTab';
import MapTab from '../../components/MapTab/MapTab';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';

import { DataContext } from '../../App';


function HomePage() {
     const {pagelang} = useParams();
    const [pageContent, setPageContent] = React.useState({});
    const [promtion_slider_data, setPromotionSliderData] = React.useState([]);
    const [lastest_news_slider_data, setLastestNewsSliderData] = React.useState([]);
    const [tab_item_data, setTabItemData] = React.useState([]);
    const { t } = useTranslation();
    const allpagedata = useContext(DataContext);
    const curLang = (pagelang) ? pagelang : i18n.resolvedLanguage;
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    
    // let location = useLocation();
    // const curPath = location.pathname;
    // useEffect(() => {
    //   // console.log("homepage", curPath.includes("/en"));
    //   if (curPath.includes("/tc")) {
    //     i18n.changeLanguage("tc");
    //   } else {
    //     i18n.changeLanguage("en");
    //   }
    // }, [curPath]);

    

    var promotion_slider_settings = {
      dots: false,
      infinite: true,
      autoplay:true,
      autoplaySpeed:3000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    var lastest_news_slider_settings = {
      dots: false,
      infinite: true,
      autoplay:false,
      autoplaySpeed:3000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            dots:false
          }
        }
      ]
    };

    useEffect(() => {
      const get_promtion_slider_data = async () => {
        const genres = await resources_callapi("promotion_banner");
        setPromotionSliderData(genres);
      }
      get_promtion_slider_data();
      // console.log("🚀 ~ constget_promtion_slider_data= test ~ promtion_slider_data:", promtion_slider_data)

      const get_page_content = async () => {
        const api_page_content = await resources_callapi("index_info", pagelang);
        setPageContent(api_page_content.find((item) => item.slug == curLang).acf);
      }
      get_page_content();

      const get_tab_item_list = async () => {
        const api_tab_item_list = await resources_callapi("travelbymontab" ,"", "per_page=100");
        setTabItemData(api_tab_item_list);
      }
      get_tab_item_list();

      const get_lastest_news_slider_data = async () => {
        const api_lastest_news_slider_data = await resources_callapi("lastest_news");
        setLastestNewsSliderData(api_lastest_news_slider_data);
      }
      get_lastest_news_slider_data();
    }, [curLang]);
    
    console.log("🚀 ~ constget_page_content= ~ api_page_content:", pageContent);
    console.log("🚀 ~ constget_page_content= ~ allpagedata:", allpagedata);

    return (
      <div className="homepage-container">
        {/* <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="eventdeails" element={<EventDeails />} />
        </Routes>
        <Outlet /> */}
        <Helmet>
          <title>Wild Senses - Home</title>
        </Helmet>


        <div className="page-content">
          <div className="slider-container">
            <Slider {...promotion_slider_settings}>
              {promtion_slider_data.map((tabItem,index) => {
                return <div key={index}>
                <div className="news-item-container">
                  <div className="bg-img-container" style={{background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%),url(${(tabItem.acf.banner_img_gp)?tabItem.acf.banner_img_gp[(isMobile)?'mobile':'desktop']:''}) lightgray 50% / cover no-repeat`}}>
                  </div>
                  <div className="news-content">
                    <div className="info-top">
                      <img className="top-info-icon" src={top_info_icon} alt="" />
                      <div className="info-name">{tabItem.acf.top_info_name[curLang]}</div>
                    </div>
                    <div className="desp">{tabItem.acf.banner_content[curLang]}</div>
                    {(tabItem.acf.banner_link)? 
                        (tabItem.acf.banner_link[curLang].includes('http'))?
                          <a href={tabItem.acf.banner_link[curLang]} target='_blank' className="btn-link" rel="noreferrer">
                            <div className="btn-text">{t('explore_btn_name')}</div>
                          </a>
                        :
                          <Link to={tabItem.acf.banner_link[curLang]} className="btn-link">
                            <div className="btn-text">{t('explore_btn_name')}</div>
                          </Link>
                      :''
                    }
                  </div>
                </div>
              </div>;
              })}
            </Slider>
          </div>
          <ContactUs></ContactUs>
          <div className="info-details-section">
            <div className="section-title">
              {(pageContent.info_details)?pageContent.info_details.title:""}
            </div>
            <div className="line-break">
              <div className="line"></div>
            </div>
            <div className="section-desp">
              {(pageContent.info_details)?pageContent.info_details.desp:""}
            </div>
            <div className="circle-container">
              <div className="circle-item">
                <div className="item-detail">
                  <div className="item-icon"><img src={(pageContent.info_details)?pageContent.info_details.circle_item_1.icon_img:""} alt="" /></div>
                  <div className="item-title">{(pageContent.info_details)?pageContent.info_details.circle_item_1.item_title:""}</div>
                  <div className="item-content">{(pageContent.info_details)?pageContent.info_details.circle_item_1.item_content:""}</div>
                </div>
              </div>
              <div className="circle-item">
                <div className="item-detail">
                  <div className="item-icon"><img src={(pageContent.info_details)?pageContent.info_details.circle_item_2.icon_img:""} alt="" /></div>
                  <div className="item-title">{(pageContent.info_details)?pageContent.info_details.circle_item_2.item_title:""}</div>
                  <div className="item-content">{(pageContent.info_details)?pageContent.info_details.circle_item_2.item_content:""}</div>
                </div>
              </div>
              <div className="circle-item">
                <div className="item-detail">
                  <div className="item-icon"><img src={(pageContent.info_details)?pageContent.info_details.circle_item_3.icon_img:""} alt="" /></div>
                  <div className="item-title">{(pageContent.info_details)?pageContent.info_details.circle_item_3.item_title:""}</div>
                  <div className="item-content">{(pageContent.info_details)?pageContent.info_details.circle_item_3.item_content:""}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="travel-idea-section">
            <div className="section-details-container">
              <div className="section-title">{t('travel_idea_section_title')}</div>
              <TravelIdeaTab tabItem={tab_item_data} tabInfo={(pageContent.travel_by_month)?pageContent.travel_by_month:""}></TravelIdeaTab>
            </div>
          </div>
          <div className="lastest-news-slider-container">
            <Slider {...lastest_news_slider_settings}>
              
            {lastest_news_slider_data.map((newsItem,index) => {
              
              return (
                <div key={index}>
                  <div className="news-item-container">
                    <div className="news-content-section">
                      <div className="news-content">
                        <div className="title">{newsItem.acf.title[curLang]}</div>
                        <div className="news-line">
                          <div className="line"></div>
                        </div>
                        <div className="sub-title">{newsItem.acf.sub_title[curLang]}</div>
                        <div className="desp">{newsItem.acf.desp[curLang]}</div>
                        <div className="rm-btn-link">
                          <div className="btn-text">{t('read_more_btn_name')}</div>
                          <div className="btn-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12.7481 3.50128L20.3019 3.50179L20.4021 3.51571L20.5009 3.54331L20.5613 3.56888C20.6405 3.60371 20.7147 3.65439 20.7799 3.71959L20.8206 3.76363L20.8812 3.84511L20.9183 3.91014L20.957 4.00045L20.9762 4.06458L20.9898 4.12852L20.9991 4.22172L20.9996 11.7552C20.9996 12.1694 20.6639 12.5052 20.2496 12.5052C19.8699 12.5052 19.5561 12.223 19.5065 11.8569L19.4996 11.7552L19.4992 6.05928L4.27958 21.2846C4.01335 21.5509 3.59669 21.5751 3.30305 21.3573L3.21892 21.2847C2.95263 21.0184 2.92837 20.6018 3.14619 20.3082L3.2188 20.224L18.4382 5.00028L12.7481 5.00128C12.3684 5.00128 12.0546 4.71913 12.005 4.35305L11.9981 4.25128C11.9981 3.87159 12.2803 3.55779 12.6463 3.50813L12.7481 3.50128Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-content">
                      <img
                        className="news-bg-img"
                        src={newsItem.acf.banner_img}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            </Slider>
          </div>
          <div className="our-destinations-section">
            <div className="section-title">{(pageContent.destinations)?pageContent.destinations.title:""}</div>

            <div className="section-item">
                <div className="section-item-title">
                  <div className="title-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M18.657 16.8823L17.47 18.0562C16.5951 18.9149 15.46 20.0188 14.0642 21.3684C13.1919 22.2117 11.8081 22.2116 10.936 21.3681L7.44487 17.9723C7.00611 17.5414 6.63884 17.1781 6.343 16.8823C2.94259 13.4819 2.94259 7.96872 6.343 4.56831C9.74341 1.1679 15.2566 1.1679 18.657 4.56831C22.0574 7.96872 22.0574 13.4819 18.657 16.8823ZM15.0002 11C15.0002 9.61918 13.8808 8.4998 12.5 8.4998C11.1192 8.4998 9.99979 9.61918 9.99979 11C9.99979 12.3808 11.1192 13.5002 12.5 13.5002C13.8808 13.5002 15.0002 12.3808 15.0002 11Z" fill="#BC9357"/>
                    </svg>
                  </div>
                  <div className="title-text">{(pageContent.destinations)?pageContent.destinations.africa:""}</div>
                </div>
                <div className="destinations-item-container">
                  {(allpagedata && allpagedata.destination_afric_pagelist)?allpagedata.destination_afric_pagelist.map((des_item)=>{
                    const [name,subname] = des_item.slug.split('_');
                    if(subname === undefined) {
                    // console.log("🚀 ~ HomePage ~ allpa gedata.destination_pagelist:", des_item,des_item.homepage_icon_img)
                    return <Link to={`/${curLang}/destination/Africa/${name}`} className="destinations-item">
                      <div className="item-details">
                        <div className="item-img">
                          <img src={des_item.acf.homepage_icon_img} alt="" />
                          <div className="grey-layer"></div>
                        </div>
                        <div className="item-placename">
                          <div className="name">{des_item.acf.page_url_name[curLang]}</div>
                          <div className="rm-btn">
                            <div className="btn-text">{t('read_more_btn_name')}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                              <path d="M13.2222 3.0013L20.776 3.00181L20.8762 3.01573L20.975 3.04332L21.0354 3.06889C21.1146 3.10373 21.1888 3.15441 21.254 3.21961L21.2947 3.26365L21.3553 3.34513L21.3925 3.41015L21.4311 3.50047L21.4503 3.56459L21.4639 3.62854L21.4732 3.72173L21.4737 11.2552C21.4737 11.6694 21.138 12.0052 20.7237 12.0052C20.344 12.0052 20.0303 11.723 19.9806 11.357L19.9737 11.2552L19.9733 5.5593L4.75369 20.7846C4.48745 21.0509 4.07079 21.0751 3.77716 20.8573L3.69303 20.7847C3.42673 20.5185 3.40248 20.1018 3.6203 19.8082L3.69291 19.724L18.9123 4.5003L13.2222 4.5013C12.8425 4.5013 12.5287 4.21914 12.4791 3.85307L12.4722 3.7513C12.4722 3.3716 12.7544 3.05781 13.1204 3.00814L13.2222 3.0013Z" fill="white"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </Link>
                  }
                  }):''}
                </div>
              </div>
              <div className="section-item">
                  <div className="section-item-title">
                    <div className="title-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M18.657 16.8823L17.47 18.0562C16.5951 18.9149 15.46 20.0188 14.0642 21.3684C13.1919 22.2117 11.8081 22.2116 10.936 21.3681L7.44487 17.9723C7.00611 17.5414 6.63884 17.1781 6.343 16.8823C2.94259 13.4819 2.94259 7.96872 6.343 4.56831C9.74341 1.1679 15.2566 1.1679 18.657 4.56831C22.0574 7.96872 22.0574 13.4819 18.657 16.8823ZM15.0002 11C15.0002 9.61918 13.8808 8.4998 12.5 8.4998C11.1192 8.4998 9.99979 9.61918 9.99979 11C9.99979 12.3808 11.1192 13.5002 12.5 13.5002C13.8808 13.5002 15.0002 12.3808 15.0002 11Z" fill="#BC9357"/>
                      </svg>
                    </div>
                    <div className="title-text">{(pageContent.destinations)?pageContent.destinations.rest_of_world:""}</div>
                  </div>
                  <div className="destinations-item-container">
                    {(allpagedata && allpagedata.destination_rotw_pagelist)?allpagedata.destination_rotw_pagelist.map((des_item)=>{
                      const [name,subname] = des_item.slug.split('_');
                      if(subname == undefined) {
                      // console.log("🚀 ~ HomePage ~ allpa gedata.destination_pagelist:", des_item,des_item.homepage_icon_img)
                      return <Link to={`/${curLang}/destination/rest_of_the_world/${name}`} className="destinations-item">
                        <div className="item-details">
                          <div className="item-img">
                            <img src={des_item.acf.homepage_icon_img} alt="" />
                            <div className="grey-layer"></div>
                          </div>
                          <div className="item-placename">
                            <div className="name">{des_item.acf.page_url_name[curLang]}</div>
                            <div className="rm-btn">
                              <div className="btn-text">{t('read_more_btn_name')}</div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M13.2222 3.0013L20.776 3.00181L20.8762 3.01573L20.975 3.04332L21.0354 3.06889C21.1146 3.10373 21.1888 3.15441 21.254 3.21961L21.2947 3.26365L21.3553 3.34513L21.3925 3.41015L21.4311 3.50047L21.4503 3.56459L21.4639 3.62854L21.4732 3.72173L21.4737 11.2552C21.4737 11.6694 21.138 12.0052 20.7237 12.0052C20.344 12.0052 20.0303 11.723 19.9806 11.357L19.9737 11.2552L19.9733 5.5593L4.75369 20.7846C4.48745 21.0509 4.07079 21.0751 3.77716 20.8573L3.69303 20.7847C3.42673 20.5185 3.40248 20.1018 3.6203 19.8082L3.69291 19.724L18.9123 4.5003L13.2222 4.5013C12.8425 4.5013 12.5287 4.21914 12.4791 3.85307L12.4722 3.7513C12.4722 3.3716 12.7544 3.05781 13.1204 3.00814L13.2222 3.0013Z" fill="white"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </Link>
                    }
                    }):''}
                  </div>
                </div>

            {/* {des_fake_data.map((des_section_item)=>{
              return <div className="section-item">
                <div className="section-item-title">
                  <div className="title-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M18.657 16.8823L17.47 18.0562C16.5951 18.9149 15.46 20.0188 14.0642 21.3684C13.1919 22.2117 11.8081 22.2116 10.936 21.3681L7.44487 17.9723C7.00611 17.5414 6.63884 17.1781 6.343 16.8823C2.94259 13.4819 2.94259 7.96872 6.343 4.56831C9.74341 1.1679 15.2566 1.1679 18.657 4.56831C22.0574 7.96872 22.0574 13.4819 18.657 16.8823ZM15.0002 11C15.0002 9.61918 13.8808 8.4998 12.5 8.4998C11.1192 8.4998 9.99979 9.61918 9.99979 11C9.99979 12.3808 11.1192 13.5002 12.5 13.5002C13.8808 13.5002 15.0002 12.3808 15.0002 11Z" fill="#BC9357"/>
                    </svg>
                  </div>
                  <div className="title-text">{des_section_item.section_name}</div>
                </div>
                <div className="destinations-item-container">
                  {des_section_item.section_items.map((des_item)=>{
                    return <Link to={`/${curLang}/game`} className="destinations-item">
                      <div className="item-details">
                        <div className="item-img">
                          <img src={des_item.place_img} alt="" />
                          <div className="grey-layer"></div>
                        </div>
                        <div className="item-placename">
                          <div className="name">{des_item.place_name}</div>
                          <div className="rm-btn">
                            <div className="btn-text">Read More</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                              <path d="M13.2222 3.0013L20.776 3.00181L20.8762 3.01573L20.975 3.04332L21.0354 3.06889C21.1146 3.10373 21.1888 3.15441 21.254 3.21961L21.2947 3.26365L21.3553 3.34513L21.3925 3.41015L21.4311 3.50047L21.4503 3.56459L21.4639 3.62854L21.4732 3.72173L21.4737 11.2552C21.4737 11.6694 21.138 12.0052 20.7237 12.0052C20.344 12.0052 20.0303 11.723 19.9806 11.357L19.9737 11.2552L19.9733 5.5593L4.75369 20.7846C4.48745 21.0509 4.07079 21.0751 3.77716 20.8573L3.69303 20.7847C3.42673 20.5185 3.40248 20.1018 3.6203 19.8082L3.69291 19.724L18.9123 4.5003L13.2222 4.5013C12.8425 4.5013 12.5287 4.21914 12.4791 3.85307L12.4722 3.7513C12.4722 3.3716 12.7544 3.05781 13.1204 3.00814L13.2222 3.0013Z" fill="white"/>
                            </svg>
                          </div>
                        </div>

                      </div>
                    </Link>
                  })}
                </div>
              </div>
            })} */}

            
          </div>
          <div className="map-section">
            <div className="section-details-container">
              <div className="section-title">{t('migration_map_section_title')}</div>
              <div className="map-tab-section"><MapTab></MapTab></div>
            </div>
          </div>
        </div>

        <ContactUsForm></ContactUsForm>


      </div>
    );
  }
  
  export default HomePage;