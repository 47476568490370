// import { useDispatch } from "react-redux/es/hooks/useDispatch";
import React, { useState,useEffect,useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
import { HashRouter as Router, Routes , Route, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import i18n from 'i18next';
import Slider from "react-slick";
import './ContactUs.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {resources_callapi} from'../../common/CallApi';

// image
import footer_call from '../../assets/common/footer_call.png';
import footer_mail from '../../assets/common/footer_mail.png';
import footer_hour from '../../assets/common/footer_hour.png';

// components
import CheckLang from '../../components/common/CheckLang';
import ContactUs from '../../components/ContactUsBtn/ContactUsBtn';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';

import { DataContext } from '../../App';


function ContactUsPage() {
     const {pagelang} = useParams();
    const { t } = useTranslation();
    const allpagedata = useContext(DataContext);
    const [curLang, setCurLang] = useState(i18n.resolvedLanguage);
    const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
    

    useEffect (()=>{
      setCurLang (i18n.resolvedLanguage);
    },[i18n.resolvedLanguage])

    return (
      (allpagedata.contactus_form)?

      <div className="contactuspage-container">
        <Helmet>
          <title>Wild Senses</title>
        </Helmet>
        
        <div className="banner-section" style={(isMobile)?{backgroundImage: `url(`+ allpagedata.contactus_form.acf.banner_img.mobile +`)`}:{backgroundImage: `url(`+ allpagedata.contactus_form.acf.banner_img.desktop +`)`}}>
            <div className="banner-text-section">
              <div className="page-title">
                <div className="page-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 1.46991C11.7626 1.17702 12.2374 1.17702 12.5303 1.46991L22.5303 11.4699C22.8232 11.7628 22.8232 12.2377 22.5303 12.5306L12.5303 22.5306C12.2374 22.8235 11.7626 22.8235 11.4697 22.5306L1.46967 12.5306C1.17678 12.2377 1.17678 11.7628 1.46967 11.4699L11.4697 1.46991ZM3.06066 12.0002L12 20.9396L20.9393 12.0002L12 3.0609L3.06066 12.0002Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12.0002C1.25 11.586 1.58579 11.2502 2 11.2502H22C22.4142 11.2502 22.75 11.586 22.75 12.0002C22.75 12.4145 22.4142 12.7502 22 12.7502H2C1.58579 12.7502 1.25 12.4145 1.25 12.0002Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2155 1.28183C12.6123 1.40085 12.8374 1.81896 12.7184 2.21571L9.78302 12.0002L12.7184 21.7847C12.8374 22.1814 12.6123 22.5995 12.2155 22.7186C11.8188 22.8376 11.4007 22.6125 11.2816 22.2157L8.28163 12.2157C8.23946 12.0751 8.23946 11.9253 8.28163 11.7847L11.2816 1.78469C11.4007 1.38794 11.8188 1.1628 12.2155 1.28183Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7847 1.28183C12.1814 1.1628 12.5995 1.38794 12.7186 1.78469L15.7186 11.7847C15.7607 11.9253 15.7607 12.0751 15.7186 12.2157L12.7186 22.2157C12.5995 22.6125 12.1814 22.8376 11.7847 22.7186C11.3879 22.5995 11.1628 22.1814 11.2818 21.7847L14.2172 12.0002L11.2818 2.21571C11.1628 1.81896 11.3879 1.40085 11.7847 1.28183Z" fill="white"/>
                  </svg>
                </div>
                <div className="page-name">{allpagedata.contactus_form.acf.banner_page_title[curLang]}</div>
              </div>
              <div className="sub-title">{allpagedata.contactus_form.acf.banner_page_subtitle[curLang]}</div>
            </div>
          </div>
          <div className="page-content">
            <div className="info-section">
              <div className="info-item info-tel">
                <span className="info-icon">
                  <img src={footer_call} alt="" className="info-icon call-icon" />
                </span>
                <span className="info-details">(852) 2813 8778</span>
              </div>
              <div className="info-item info-email">
                <span className="info-icon">
                  <img src={footer_mail} alt="" className="info-icon mail-icon" />
                </span>
                <span className="info-details">enquiry@wildsensesholidays.com</span>
              </div>
              <div className="info-item info-hours">
                <span className="info-icon">
                  <img src={footer_hour} alt="" className="info-icon hour-icon" />
                </span>
                <span className="info-details"> Mon-Sat  10:00 - 18:00 <br />Sun & PH Closed</span>
              </div>
            </div>
            <ContactUsForm></ContactUsForm>
          </div>
      </div>

      :<div></div>
    );
  }
  
  export default ContactUsPage;