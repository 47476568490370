import axios from 'axios';

function getObjects() {
  const useful_field = "_fields=id,slug,title.rendered,acf";
  const base_url = "https://api.wildsensesholidays.com/wp-json/wp/v2"

  let result_url = base_url + "main" +useful_field;
  return axios.get(result_url);
}

const resources_callapi = async (postName,slugField,otherFilter,pageType) => {
  let resp;
  // lastest_news…

  const base_url = "https://api.wildsensesholidays.com/wp-json/wp/v2/";
  const useful_field = "_fields=id,slug,title.rendered,acf,content";
  const pagelist_type = "_fields=id,slug,acf.page_url_name";


  let result_url = base_url + postName;
  switch (pageType) {
    case "page_list":
      result_url= result_url+ "?"+ pagelist_type;
      break;
    case "other":
      result_url= result_url+ "?";
      break;
    default:
      result_url= result_url+ "?"+ useful_field;

  };
  if (slugField && slugField !== "") {result_url = result_url + "&slug=" + slugField}
  if (otherFilter && otherFilter !== "") {result_url = result_url + "&" + otherFilter}

  await axios.get(result_url).then(
    result => {
      // console.log("test", result.data);
      resp = result.data;
    }
  )
  return resp;
}

export {
  getObjects,
  resources_callapi,
};